<template>
  <div class="status" :class="status"></div>
</template>
<script>
export default {
  components: {},
  props: {
    status: {
      type: String,
      default () { return 'active' }
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .status
    display: inline-block
    width: 10px
    height: 10px
    border-radius: 50%
    background-color: #BF360C
    &.active
      background-color: #689F38
</style>
